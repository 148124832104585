html {
    scroll-behavior: smooth;
}

body {
    /* background-image: radial-gradient(circle, #171424, black) !important; */
    /* background-color: transparent !important; */
    /* background-image: url('https://www.csgoroll.com/assets/fire-particles-bg.png'); */
    /* background-image: url(bg-sparks-gradient-2.png);
    background-size: cover; */
    background-color: '#222';
    background-image: url(bg-sparks-gradient-3.png);
    background-size: cover;
}

.bg-before {
    background-image: radial-gradient(circle, #1714245c, rgba(0, 0, 0, 0.378)) !important;
}
.bg-before::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(bg-sparks-gradient-2.png);
    filter: blur(4px);
    background-size: 110%;
    background-repeat: no-repeat;
    transform: scaleX(-1);
    z-index: -1;
    opacity: .66;
}

body:has(.noScroll) {
    -ms-overflow-style: none;
}

body:has(.noScroll)::-webkit-scrollbar {
    display: none;
}

.apexcharts-menu {
    background: #333 !important;
}

.apexcharts-menu .apexcharts-menu-item {
    color: "white" !important;
}

body>iframe[style*='2147483647'] {
    display: none;
}

.css-m01nxn {
    display: none;
}

.custom-scrollbar {
    scrollbar-width: thin !important;
    scrollbar-color: #454f5bc7 transparent !important;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #454f5bc7 !important;
    border-radius: 8px !important;
    border: 0px solid #ffffff !important;
}

ul {
    padding-left: 32px;
}